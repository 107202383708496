import React, { ReactNode, useEffect, useRef } from 'react'
import s from './index.module.scss'
import cn from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import Image from 'next/image'
import { useRouter } from 'next/router'

type card = {
  id?: number | string
  title?: string | ReactNode
  content?: string | ReactNode
  icon?: string
  wrapperClass?: string
  typeBg?: string
  aos?: boolean
}
type Iprops = {
  wrapperClass?: string
  cardClass?: string
  cardItemClass?: string
  list: card[]
  cardType: 1 | 2 | 3 | 4 | 5 | 'custom'
  renderChild?: (item: any, index: number) => ReactNode
  typeBg?: string
  aos?: boolean
  swiperIndex?: number
  onSlideChange?: (index: number) => void
}
const CardTtype1 = function (props: card) {
  const { title, content, aos } = props
  const dataAos = aos ? 'fade-up' : ''

  return (
    <div className={cn(s.card_swiper_item, s.card_type_1)}>
      {title && (
        <div className={cn(s.card_swiper_title, 'title3')} data-aos={dataAos}>
          {title}
        </div>
      )}
      {content && (
        <div data-aos={dataAos} className={cn(s.card_swiper_content, 'text3', { [s.card_swiper_content_tip]: !title })}>
          {content}
        </div>
      )}
    </div>
  )
}
const CardTtype2 = function (props: card) {
  const { title, content, icon, wrapperClass, aos } = props
  const dataAos = aos ? 'fade-up' : ''

  return (
    <div className={cn(s.card_swiper_item, s.card_type_2, wrapperClass)}>
      {title && (
        <div className={cn(s.card_swiper_title, 'title3')} data-aos={dataAos}>
          {title}
        </div>
      )}
      {content && (
        <div data-aos={dataAos} className={cn(s.card_swiper_content, 'text3', { [s.card_swiper_content_tip]: !title })}>
          {content}
        </div>
      )}
      {icon && (
        <div className={s.card_swiper_bg} data-aos={dataAos}>
          <Image src={icon} layout="fill" alt=""></Image>
        </div>
      )}
    </div>
  )
}
const CardTtype3 = function (props: card) {
  const { content, icon, wrapperClass, aos } = props
  const dataAos = aos ? 'fade-up' : ''

  return (
    <div className={cn(s.card_swiper_item, s.card_type_3, wrapperClass)}>
      <div className={cn(s.card_swiper_content, 'text3')} data-aos={dataAos}>
        {content}
      </div>
      {icon && (
        <div className={s.card_swiper_bg} data-aos={dataAos}>
          <Image src={icon} layout="fill" alt=""></Image>
        </div>
      )}
    </div>
  )
}
const CardTtype4 = function (props: card) {
  const { title, content, icon, wrapperClass, typeBg, aos } = props
  const dataAos = aos ? 'fade-up' : ''

  return (
    <div className={cn(s.card_swiper_item, s.card_type_4, wrapperClass)}>
      <div className={cn(s.card_swiper_title, 'title3', { [s.card_swiper_title_Blue]: typeBg == 'other' })} data-aos={dataAos}>
        {title}
        {icon && (
          <div className={s.card_swiper_bg}>
            <Image src={icon} layout="fill" alt=""></Image>
          </div>
        )}
      </div>
      <div data-aos={dataAos} className={cn(s.card_swiper_content, 'text3', { [s.card_swiper_content_tip]: !title })}>
        {content}
      </div>
    </div>
  )
}

const CardTtype5 = function (props: card) {
  const { title, content, icon, aos } = props
  const dataAos = aos ? 'fade-up' : ''

  return (
    <div className={cn(s.card_swiper_item, s.card_type_5)}>
      {title && (
        <div className={cn(s.card_swiper_title, 'title3')} data-aos={dataAos}>
          {title}
        </div>
      )}
      {content && (
        <div data-aos={dataAos} className={cn(s.card_swiper_content, 'text3', { [s.card_swiper_content_tip]: !title })}>
          {content}
        </div>
      )}
      {icon && (
        <div className={s.card_swiper_bg} data-aos={dataAos}>
          <Image src={icon} layout="fill" alt=""></Image>
        </div>
      )}
    </div>
  )
}
export default function HomeOurClient(props: Iprops) {
  const router = useRouter()

  const { list, cardType, renderChild, wrapperClass, cardClass, typeBg, cardItemClass, aos, swiperIndex = 0, onSlideChange = null } = props
  const swiperRef = useRef<any>(swiperIndex)
  useEffect(() => {
    swiperRef.current?.slideTo(swiperIndex)
  }, [swiperIndex])

  return (
    <Swiper
      className={cn(s.swiper_card_list, wrapperClass)}
      speed={400} // 切换速度
      slidesPerView={'auto'} // 同时展示的个数
      spaceBetween={0}
      centeredSlides={false}
      onSwiper={(swiper) => {
        swiperRef.current = swiper
      }}
      onSlideChange={(swiper) => {
        onSlideChange && onSlideChange(swiper.activeIndex)
      }}

    >
      {list.map((item, index) => {
        return (
          <SwiperSlide key={index} virtualIndex={index} className={cn(s.swiper_card_item, cardItemClass, { [s.swiper_card_item_ar]: router.locale == 'ar' })}>
            {cardType === 1 && <CardTtype1 {...item} wrapperClass={cardClass} aos={aos} />}
            {cardType === 2 && <CardTtype2 {...item} wrapperClass={cardClass} aos={aos} />}
            {cardType === 3 && <CardTtype3 {...item} wrapperClass={cardClass} aos={aos} />}
            {cardType === 4 && <CardTtype4 {...item} typeBg={typeBg} wrapperClass={cardClass} aos={aos} />}
            {cardType === 5 && <CardTtype5 {...item} wrapperClass={cardClass} aos={aos} />}
            {cardType === 'custom' && renderChild && renderChild(item, index)}
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}
